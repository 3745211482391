<!--
 * @Author: snltty
 * @Date: 2021-10-11 09:30:14
 * @LastEditors: snltty
 * @LastEditTime: 2021-10-12 10:00:16
 * @version: v1.0.0
 * @Descripttion: 功能说明
 * @FilePath: \pc\src\views\Home.vue
-->
<template>
    <div class="home">
        <el-carousel :interval="10000" arrow="always" class="carousel">
            <el-carousel-item>
                <div class="video">
                    <video autoplay loop muted>
                        <source src="images/video.mp4" type="video/mp4" />
                    </video>
                </div>
            </el-carousel-item>
            <template v-for="(item,index) in images" :key="index">
                <el-carousel-item>
                    <div class="img" :style="{'background-image':`url(${item})`}"></div>
                </el-carousel-item>
            </template>
        </el-carousel>
    </div>
</template>
<script>
export default {
    name: 'Home',
    setup () {
        const images = ['./images/1.jpg', './images/2.jpg', './images/3.jpg', './images/4.jpg', './images/5.jpg'];
        return {
            images
        }
    }
}
</script>
<style lang="stylus">
@media screen and (max-width: 768px)
    .home
        .carousel
            .el-carousel__container
                height: calc(100vh - 61px) !important;

.home
    .carousel
        .el-carousel__container
            height: calc(100vh - 132px);

    .img
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;

    .video
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;

        video
            width: 100%;
            height: 100%;
            object-fit: cover;
</style>
